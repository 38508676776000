<template>
  <a-time-picker
    v-bind="record.options"
    :value="value"
    :value-format="valueFormat"
    @change="change"
    @openChange="openChange"
  />
</template>

<script>
import formEleMixin from './form-ele-mixin'
export default {
  name: 'AxTimePicker',
  mixins: [formEleMixin],
  computed: {
    valueFormat () {
      return this.record.options.format || 'HH:mm:ss'
    }
  },
  methods: {
    /**
     * 时间发生变化的回调
     * @param value
     */
    change (value) {
      this.$emit('change', value, this.record.model)
    },
    /**
     * 面板打开/关闭时的回调
     * @param open
     */
    openChange (open) {
      this.$emit('openChange', open, this.record.model)
    }
  }
}
</script>
