export default {
  props: ['record', 'value', 'dynamicData'],
  model: {
    props: 'value',
    event: 'change'
  },
  computed: {
    labelKey () {
      return this.record.options.labelKey || 'label'
    },
    valueKey () {
      return this.record.options.valueKey || 'value'
    },
    extraLabelKey () {
      return this.record.options.extraLabelKey || 'label'
    },
    options () {
      const { optionsType = 'staticData', options, dicData } = this.record.options
      switch (optionsType) {
        case 'staticData':
          return options
        case 'dicData':
          console.log(dicData)
          return []
        case 'remoteData':
          return []
      }
    },
    isSelectAll () {
      if (typeof this.record.options.isSelectAll === 'boolean') {
        return this.record.options.isSelectAll
      } else if (typeof this.$ax.config?.select?.isSelectAll === 'boolean') {
        return this.$ax.config?.select?.isSelectAll
      } else {
        return false
      }
    }
  },
  methods: {
    getDataType (value) {
      return this.$ax.tools.getDataType(value)
    },
    isEmpty (value) {
      return this.$ax.tools.isEmpty(value)
    },
    formatValue (value, type = 'toArr') {
      return this.$ax.tools.formatValue(value, type)
    },
    valueForEmpty (value, defaultValue) {
      return this.$ax.tools.valueForEmpty(value, defaultValue)
    }
  }
}
